import { computed, ref } from 'vue'
import { useRoute } from '#app'
import ConstructorIdentify from '@constructor-io/constructorio-id'
import { BREADCRUMBS_EXCLUDED } from '~/constants/catalog'
import useVariantDetails from '~/composables/useVariantDetails'
import { findBeyondPlasticBadge } from '~/utils/beyond-plastic-tag'

import type {
  CatalogBreadcrumb,
  ConstructorGroup,
  ConstructorResult,
  ConstructorFacet,
  CatalogItem,
  CatalogFilter,
  CatalogFilterOption,
  CatalogLink,
} from '~/types/catalog'

export const FILTER_PARAM = 'filters'

// Base Constructor.io URL Constants
const baseUrl = new URL('https://ac.cnstrc.com')
baseUrl.searchParams.set('c', 'cio-fe-web-grove')
baseUrl.searchParams.set('key', 'key_dolYQIgR2E0Wf71o')

const BEYOND_PLASTIC_DESCRIPTION =
  'Products that are 100% plastic-free, 95%+ plastic-free, or have no single-use plastic.'

const discountFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
})

/**
 * Build breadcrumbs from Constructor groups.
 *
 */
const buildBreadcrumbs = (
  groups: ConstructorGroup['parents']
): CatalogBreadcrumb[] => {
  if (!groups) {
    return []
  }
  return groups
    .filter(({ group_id: id }) => id && !BREADCRUMBS_EXCLUDED.includes(id))
    .map(({ group_id: id, display_name: text }) => ({
      id,
      text,
      url: `/catalog/?category=${id}`,
    }))
}

/**
 * Detect specific Constructor Searchandizing Tags that should be
 * converted to category links.
 */
const buildContentLinks = (content?: Record<string, any>): CatalogLink[] => {
  if (!content) return []
  return Object.entries(content)
    .map(([key, text]): CatalogLink | null => {
      const [id, searchParam, value] = key.split(',')
      if (value && searchParam && id) {
        return {
          id,
          selected: computed(() => value === useRoute().query[searchParam]),
          text,
          url: `/catalog/?${searchParam}=${value}`,
        }
      }
      return null
    })
    .filter((catalogLink) => catalogLink !== null)
    .sort((a, b) => {
      if (a.id < b.id) return -1
      if (a.id > b.id) return 1
      return 0
    })
}

/**
 * Translates a Constructor facet into a filter option
 *
 */
const buildFilterFromFacet = (facet: ConstructorFacet): CatalogFilter => ({
  id: facet.name,
  name: facet.display_name,
  type: facet.type,
  options:
    facet.options.map(
      (option): CatalogFilterOption => ({
        id: option.value?.replaceAll('"', ''),
        count: option.count,
        description:
          // Optional description may eventually come from option.data.description
          option.value === '100% Plastic Free'
            ? BEYOND_PLASTIC_DESCRIPTION
            : '',
        name: option.display_name,
        selected: option.status === 'selected',
      })
    ) || [],
})

/**
 * Normalizes a Constructor.io item into a single search result.
 * Adds computed properties to the result such as discountText and secondaryText.
 *
 */
const buildResult = (result: ConstructorResult): CatalogItem => {
  const { data, strategy = {}, value: name, variations = [] } = result
  const { id, variation_id: variantId } = data
  const variantDetails = variantId ? useVariantDetails()[variantId] : ref()

  const variants = variations.map(({ data }) => {
    return {
      variantId: data.variation_id,
      variantName: data.short_name,
    }
  })

  const beyondPlasticBadge = findBeyondPlasticBadge(data?.tags?.split('|'))

  // Single Result
  const item = {
    id,
    get savePrice() {
      return variantDetails.value?.savePrice
    },
    get hasVipSpecialPricingDiscount() {
      return variantDetails.value?.hasVipSpecialPricingDiscount
    },
    get badgeText() {
      const badgeText =
        'badgeText' in (variantDetails.value || {})
          ? variantDetails.value.badgeText
          : data?.badge_text
      return typeof badgeText === 'string' ? badgeText : ''
    },
    beyondPlasticAlt: beyondPlasticBadge?.name,
    beyondPlasticImg: beyondPlasticBadge?.image,
    brandName: data.brand_name,
    get discountText() {
      if (item.price && data.list_price > item.price) {
        return `Save ${discountFormatter.format(
          1 - item.price / data.list_price
        )}`
      }
      return ''
    },
    imageSrc: data.image_url,
    isSubscribable: data.is_subscribable,
    listPrice: data.list_price,
    name,
    get outOfStock() {
      if ('isAvailable' in (variantDetails.value || {})) {
        return !variantDetails.value.isAvailable
      }
      return 'out_of_stock' in data
    },
    get price() {
      const price = variantDetails.value?.price ?? data.price
      return typeof price === 'number' ? price : undefined
    },
    productName: data.product_short_name,
    productUrl: new URL(data.url, location.origin),
    reviewCount: data.review_count || 0,
    reviewRating: data.review_rating || 0,
    get secondaryText() {
      return [data.volume > 0 && `${data.volume} ${data.unit}`, data.short_name]
        .filter(Boolean)
        .join(' - ')
    },
    get shopifyVariantId() {
      return variantDetails.value?.shopifyVariantId?.split('/').pop()
    },
    get shopifyProductId() {
      return variantDetails.value?.shopifyProductId?.split('/').pop()
    },
    sku: data.sku,
    strategyId: strategy?.id,
    variantId,
    variantName: data.short_name,
    variants,
  }
  return item
}

/**
 * Initializes a Constructor.io request URL.
 */
const requestUrl = (): URL => {
  const { client_id: constructorClientId, session_id: constructorSessionId } =
    new ConstructorIdentify()

  // Clone the base URL
  const request = new URL(baseUrl)

  // These search params are needed for every constructor request
  request.searchParams.set('_dt', Date.now().toString())
  request.searchParams.set('i', constructorClientId)
  request.searchParams.set('s', constructorSessionId)

  return request
}

export {
  buildBreadcrumbs,
  buildContentLinks,
  buildFilterFromFacet,
  buildResult,
  requestUrl,
}
